

.sell {
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
}

.sell .bline {
    border-bottom: 7px solid #ff6a0064;
}

.sell .box-shadow-down {
    box-shadow: 5px 5px 15px rgba(0,0,0,0.3);
}

.sell .img-slice-wrap {
    width: 30%;
    overflow: visible;
}

.sell .img-slice {
    transition-property: opacity, transform;
    transition-delay: 1s;
    display: block;
    position: relative;
    width: 376px;
    height: 477px;
    -webkit-clip-path: polygon(45% 0,100% 0,55% 100%,0 100%);
    clip-path: polygon(45% 0,100% 0,55% 100%,0 100%);
    overflow: hidden;
    margin: 0;
    z-index: 1;
    transition: all .1s linear !important;
}

.sell .img {
    display: block;
    width: 100%;
    height: 384px;
    transition: all .2s ease;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.sell .img img {
    display: block;
    width: auto;
    height: 100%;
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(1px);
}

.sell .img-canvas {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;
    z-index: 1005;
}

.sell h1.first span {
    color: #d40000;
}

.sell h1.first {
    text-transform: uppercase;
}

    .sell h2 {
        font-size: 1.5em;
        font-weight: 500;
    }

.sell .facts {
    list-style: none;
    padding-left: 0;
}

.sell .facts li {
    display: flex;
    align-items: start;
    margin-top: 15px;
}

.sell .facts li span {
    display: block;
    margin-left: 10px;
}

.sell .h-icon {
    background-color: #e9dfff;
    padding: 5px;
    font-size: 22px;
    color: #222;
}